@font-face {
  font-family: coconat;
  src: url("Coconat-Regular.b4f9073d.woff2") format("woff2"), url("Coconat-Regular.2349cebf.woff") format("woff");
}

@font-face {
  font-family: combine;
  src: url("Combine.95c01f7e.otf") format("otf");
}

#background, .favicon, #animate {
  display: none;
}

body {
  font-family: coconat, Trebuchet MS, Lucida Sans Unicode, Lucida Grande, Lucida Sans, Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6, a {
  font-family: combine, Cambria, Cochin, Georgia, Times, Times New Roman, serif;
}

nav ul {
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;
  list-style: none;
  display: flex;
}

#chapter {
  width: 70%;
  margin: auto;
}

@media (width <= 800px) or (orientation: portrait) {
  #chapter {
    width: 100%;
  }
}

#chapter .page {
  background-color: #fff;
  flex-wrap: wrap;
  gap: 7px;
  max-width: 850px;
  margin: auto;
  padding: 28px;
  display: flex;
}

#chapter .page img {
  flex-grow: 0;
  width: 100%;
  max-width: max-content;
  margin: auto;
  display: block;
}

#chapter .page .chapterTitle {
  margin-top: -28px;
}

.next-chapter {
  text-align: center;
  margin-bottom: 20px;
  padding: 20px;
  display: block;
}

/*# sourceMappingURL=index.e06554d9.css.map */
