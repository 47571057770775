@import "./fonts/fonts";

#background, .favicon, #animate {
    display: none;
}

body {
    font-family: "coconat", 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
h1, h2, h3, h4, h5, h6, a {
    font-family: "combine", Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

nav ul {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
}

#chapter {
    width: 70%;
    @media (max-width: 800px) or (orientation: portrait) {
        width: 100%;
    }
    margin: auto;
    
    .page {
        display: flex;
        flex-wrap: wrap;
        max-width: 850px;
        gap: 7px;
        background-color: white;
        padding: 28px;
        margin: auto;
        
        img {
            flex-grow: 0;
            display: block;
            margin: auto;
    
            width: 100%;
            max-width: max-content;
        }

        .chapterTitle {
            margin-top: -28px;
        }
    }
}

.next-chapter {
    display: block;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
}
